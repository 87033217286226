import React from "react";
import { Link } from "react-router-dom";
import { convertTitleForURL } from "../utils";

const CardComponent = (props) => {
  const { tag, articles } = props;
  return (
    <div className="card">
      <header>
        <h3>{tag}</h3>
        {/* TODO: make a component for this */}
        {/* {tag === "Listings" && (
          <a href="/#">
            <small>see all</small>
          </a>
        )} */}
      </header>
      <ul>
        {articles?.map((a) => {
          return (
            <li key={a?.id}>
              <Link key={a?.id} to={`/post/${a?.id}?title=${encodeURIComponent(convertTitleForURL(a?.title))}`} ><h4>{a?.title}</h4></Link>
              <small>{a?.subTitle}</small> <br />
              <h5>{a?.author?.name}</h5>
              {a?.newarticle && <span>new</span>}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default CardComponent;
