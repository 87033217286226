import React from 'react'
import { Link } from 'react-router-dom'
import Content from '../Content'
import RightSidebar from '../RightSidebar'

const Home = () => {
    console.log("Home")
    return (
        <div>
            <main className="home-container">
                <Content />
                <RightSidebar />
            </main>
        </div>
    )
}

export default Home