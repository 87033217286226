import React, { useState, useEffect } from 'react'
import ReactMarkdown from 'react-markdown'
import PostContent from './Post/PostContent/PostContent'
import { posts } from '../resources/articles'
import { useParams } from 'react-router-dom';

const Post = () => {
    const { id } = useParams()
    console.log("ID:: ", id)
    console.log("POSTS:: ", posts)
    const post = posts.find((post) => post.id == id)
    console.log("POST:: ", post)
    const [data, setData] = useState(undefined)
    const [error, setError] = useState(undefined)
    useEffect(() => {
        const fetchPost = async () => {
            try {
                const response = await fetch("https://zimpler-assets.s3.ap-south-1.amazonaws.com/posts-markdown/n1.md")
                if (!response.ok) {
                    throw new Error('Network response was not ok')
                }
                const text = await response.text()
                setData(text)
            } catch (error) {
                setError(error)
            }
        }
        fetchPost()
    }, [])
    if (error) return <div>{error.message}</div>
    if (!data) return <div>Loading...</div>
    return (
        <>
            <PostContent post={post} />
            {/* <ReactMarkdown>{data}</ReactMarkdown> */}
        </>
    )
}

export default Post