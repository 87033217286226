import axios from 'axios';

const apiKey = 'c23135327e6b445cbea915da6dcff7a7';
const url = 'https://newsapi.org/v2/top-headlines';

async function getNews() {
    try {
        const response = await axios.get(url, {
            params: {
                country: 'IN',
                apiKey: apiKey
            }
        });
        return response.data.articles;
    } catch (error) {
        console.error(error);
    }
}

export default getNews;
