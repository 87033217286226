import React from "react";
import { BiHeart } from "react-icons/bi";
import { FaRegComment } from "react-icons/fa";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { Link } from "react-router-dom";
import { convertTitleForURL } from "../utils";

dayjs.extend(relativeTime);

const ArticleComponent = (props) => {
  const {
    id,
    title,
    cover_image,
    tag_list,
    url,
    comments_count,
    positive_reactions_count,
    public_reactions_count,
    author,
    published_at,
  } = props.data;

  //console.log("article data", props.data);

  return (
    <article className="article">
      {cover_image && (
        <Link key={id} to={`/post/${id}?title=${encodeURIComponent(convertTitleForURL(title))}`}
          className="article__image"
          style={{
            backgroundImage: `url(${cover_image})`,
          }}
        >
          &nbsp;
        </Link>
      )}
      <div className="article__details">
        {/* <div className="u-pic">
          <img src={author?.profile_image_90} alt="" />
        </div> */}
        <div className="u-details">
          <Link>
            <span className="u-name">{author.name}</span>
          </Link>
          <Link>
            <span className="time">
              {new Date(published_at).toLocaleDateString(undefined, {
                day: "numeric",
                month: "long",
              })}
              &nbsp; ({dayjs(published_at).fromNow()})
            </span>
          </Link>

          <Link key={id} to={`/post/${id}?title=${encodeURIComponent(convertTitleForURL(title))}`}>
            <h3>{title}</h3>
          </Link>

          <div className="tags">
            {tag_list?.map((tag, id) => {
              return (
                <a key={id} href={``}>
                  <span className="tag">#{tag}</span>
                </a>
              );
            })}
          </div>

          <div className="additional-details">
            <div className="reactions">
              {public_reactions_count + positive_reactions_count > 0 && (
                <Link key={id} to={`/post/${id}?title=${encodeURIComponent(convertTitleForURL(title))}`}>
                  <span>
                    <i>
                      <BiHeart />
                    </i>
                    &nbsp;
                    {public_reactions_count + positive_reactions_count} &nbsp;
                    <span className="hidden-mobile">reactions</span>
                  </span>
                </Link>
              )}

              <Link key={id} to={`/post/${id}?title=${encodeURIComponent(convertTitleForURL(title))}`}>
                <span>
                  <i>
                    <FaRegComment />
                  </i>
                  &nbsp;
                  {comments_count > 0 ? (
                    <span>
                      {comments_count} &nbsp;
                      <span className="hidden-mobile">comments</span>
                    </span>
                  ) : null}
                  {comments_count === 0 ? (
                    <span>
                      <span className="show-mobile">{comments_count}</span>
                      &nbsp;
                      <span className="hidden-mobile">Add comment</span>
                    </span>
                  ) : null}
                </span>
              </Link>
            </div>

            <div className="save">
              <small>1 min read</small>
              {/* <button>save</button> */}
            </div>
          </div>
        </div>
      </div>
    </article>
  );
};

export default ArticleComponent;
