import React, { useState, useEffect } from 'react';
import './QueryParamTable.css'; // Import your CSS file

function QueryParamTable() {
  const [url, setUrl] = useState('');
  const [queryParams, setQueryParams] = useState({});
  const [error, setError] = useState('');

  const handleUrlChange = (e) => {
    setUrl(e.target.value);
    setError('');
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent form submission
      handleParseUrl();
    }
  };

  const handleParseUrl = () => {
    try {
      const urlObject = new URL(url);
      const params = Object.fromEntries(urlObject.searchParams);
      setQueryParams({
        baseUrl: urlObject.origin,
        domain: urlObject.hostname,
        pathname: urlObject.pathname,
        params: Object.entries(params)?.map(([param, value]) => ({
          param,
          value,
          copied: false,
        })),
      });
      setError('');
    } catch (error) {
      setError('Invalid URL. Please enter a valid URL.');
      setQueryParams({});
    }
  };

  const handleCopy = (paramIndex) => {
    const updatedParams = [...queryParams.params];
    updatedParams[paramIndex].copied = true;

    const textToCopy = updatedParams[paramIndex].value;
    navigator.clipboard.writeText(textToCopy);

    setQueryParams({
      ...queryParams,
      params: updatedParams,
    });
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      // Reset 'copied' status for all rows after 2 seconds
      const updatedParams = queryParams.params?.map((param) => ({
        ...param,
        copied: false,
      }));
      setQueryParams({
        ...queryParams,
        params: updatedParams,
      });
    }, 2000);

    return () => clearTimeout(timer);
  }, [queryParams.params]);

  return (
    <div className="query-param-table">
      <h2>URL Parser</h2>
      <div className="url-input-container">
        <input
          type="text"
          placeholder="Enter URL"
          value={url}
          onChange={handleUrlChange}
          onKeyDown={handleKeyPress}
        />
        <button onClick={handleParseUrl}>Parse URL</button>
      </div>
      {error && <p className="error-message">{error}</p>}
      {queryParams.baseUrl && (
        <div>
          <h3>URL Info</h3>
          <div className="key-value">
            <span>Base URL:</span>
            <p>{queryParams.baseUrl}</p>
          </div>
          <div className="key-value">
            <span>Domain:</span>
            <p>{queryParams.domain}</p>
          </div>
          <div className="key-value">
            <span>Path:</span>
            <p>{queryParams.pathname}</p>
          </div>
          <h3>Query Parameters</h3>
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th>Parameter</th>
                  <th>Value</th>
                  <th>Copy</th>
                </tr>
              </thead>
              <tbody>
                {queryParams.params?.map((param, index) => (
                  <tr key={param.param}>
                    <td>{param.param}</td>
                    <td>{param.value}</td>
                    <td>
                      <button
                        onClick={() => handleCopy(index)}
                        className={param.copied ? 'copied' : ''}
                      >
                        {param.copied ? 'Copied!' : 'Copy'}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
}

export default QueryParamTable;
