import "./App.scss";
import Navigation from "./components/Navigation";
import LeftSidebar from "./components/LeftSidebar";
import Content from "./components/Content";
import RightSidebar from "./components/RightSidebar";
import Post from "./components/Post";
import React, { useState } from "react";
import { BrowserRouter as Router, Switch, Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import ComingSoon from "./components/ComingSoon";
import NewsRenderer from "./components/NewsRenderer";
import QueryParamTable from "./components/QueryParamTable/QueryParamTable";

function App() {
  const [mobileMenu, setmobileMenu] = useState(false);

  const toggle = () => {
    setmobileMenu(!mobileMenu);
  };

  return (
    <Router>
      <>
        <Navigation openMenu={toggle} />
        <main className="main-container">
          <LeftSidebar burgerMenu={mobileMenu} closeMenu={toggle} />
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/post/:id" element={<Post />} />
            <Route exact path="/about" element={<ComingSoon />} />
            <Route exact path="/contact" element={<ComingSoon />} />
            <Route exact path="/tags/:tag" element={<ComingSoon />} />
            <Route exact path="/coming-soon" element={<ComingSoon />} />
            <Route exact path="/privacy-policy" element={<ComingSoon />} />
            <Route exact path="/terms" element={<ComingSoon />} />
            {/* <Route exact path="/newsrenderer"
              // render={(props) => <newsrendererer {...props} />}
              element={<NewsRenderer />}
            /> */}
            <Route exact path="/newsrenderer"
              // render={(props) => <newsrendererer {...props} />}
              element={<ComingSoon />}
            />
            <Route exact path="/dashboard" element={<ComingSoon />} />
            <Route exact path="/login" element={<ComingSoon />} />
            <Route exact path="/profile" element={<ComingSoon />} />
            <Route exact path="/settings" element={<ComingSoon />} />
            <Route exact path="/notifications" element={<ComingSoon />} />
            <Route exact path="/reading" element={<ComingSoon />} />
            <Route exact path="/FAQ" element={<ComingSoon />} />
            <Route exact path="/privacy" element={<ComingSoon />} />
            <Route exact path="/terms" element  ={<ComingSoon />} />
            <Route exact path="/url-query-param-extracter" element={<QueryParamTable />} />
          </Routes>
        </main>
      </>
    </Router>
  );
}

export default App;
