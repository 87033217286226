import React, { useState } from "react";
import { FaDev, FaDeviantart } from "react-icons/fa";
import { BiMessageRoundedCheck } from "react-icons/bi";
import { RiNotificationLine } from "react-icons/ri";
import { FiSearch } from "react-icons/fi";
import { Link } from "react-router-dom";

const Navigation = (props) => {
  const [showMenu, setshowMenu] = useState(false);

  const toggle = () => {
    setshowMenu(!showMenu);
  };

  return (
    <header className="header">
      <div className="headerContainer">
        <div
          className="headerContainer__hamburgerMenu"
          onClick={() => props.openMenu()}
        ></div>
        <Link to="/" className="headerContainer__logo">
          <FaDeviantart size="3.125rem" />
        </Link>

        <div className="headerContainer__searchBox">
          <form>
            <input type="text" placeholder="Search..." aria-label="search" />
          </form>
        </div>

        <div className="headerContainer__right">
          {/* <button>Write a post</button> */}
          <i className="hidden-search">
            <FiSearch />
          </i>
          {/* <i>
            <BiMessageRoundedCheck />
          </i> */}
          <i>
            <RiNotificationLine />
          </i>

          <span onClick={toggle}>
            <img src="https://picsum.photos/200" alt="Profile Pictrure" />
          </span>
        </div>
      </div>

      <div className={showMenu ? "dropdown-menu" : "dropdown-menu-close"}>
        <ul>
          <li onClick={toggle}>
            <Link to="/profile">
              <div className="u-name">Zimpler</div>
              <small className="u-name-id">@zimpler</small>
            </Link>
          </li>

          <li onClick={toggle}>
            <Link to="/dashboard">Dashboard</Link>
          </li>
          <li onClick={toggle}>
            <Link to="/post">Writing a Post</Link>
          </li>
          <li onClick={toggle}>
            <Link to="/list">Reading list</Link>
          </li>
          <li onClick={toggle}>
            <Link to="/settings">Settings</Link>
          </li>
          <li onClick={toggle}>
            <Link to="/signout">Signout</Link>
          </li>
        </ul>
      </div>
    </header>
  );
};

export default Navigation;
