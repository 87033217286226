import React from 'react';
import './ComingSoon.css';

const ComingSoon = () => {
    return (
        <div className="coming-soon-container">
            <div className="coming-soon-card">
                <h1 className="coming-soon-header">We're Almost There!</h1>
                <p className="coming-soon-text">
                    We're working hard to bring you something amazing. Stay tuned for more updates!
                </p>
            </div>
        </div>
    );
};

export default ComingSoon;
