export const tags = [
    "finance",
    "technology",
    "health",
    "science",
    "sports",
    "entertainment",
    "business",
    "politics",
    "travel",
    "food",
    "music",
    "fashion",
    "art",
    "design",
];
