import React from 'react';
import './ShareButton.css';

const ShareButton = ({ onCopy, onShare }) => {
    return (
        <div className="share-button">
            <button className="share-button__copy" onClick={onCopy}>
                Copy Link
            </button>
            <button className="share-button__share" onClick={onShare}>
                <i className="fa fa-share"></i>
            </button>
        </div>
    );
};

export default ShareButton;
