import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import Avatar from '../../Avatar/Avatar';
// import Comments from '../../Comment/Comments';
// import { PostTags } from '../../PostTags/PostTags';
import { PostImage } from '../../PostImage/PostImage';
import { formatDate } from '../../../utils';
import './PostContent.css';
import SyntaxHighlight from '../../SyntaxHighlight/SyntaxHighlight';
import Table from 'react-bootstrap/Table';
import NIVESH from '../../../resources/NIVESH.md'
import gfm from "remark-gfm";
import remarkGfm from 'remark-gfm';
import ShareButton from '../../Share/ShareButton';
import { DiscussionEmbed } from 'disqus-react';
import Collapsible from "react-collapsible";
const PostContent = ({ post, handleDelete }) => {
    const { cover_image, author, titleURL, published_at, title, tags, body, id } = post;
    const createdAt = formatDate(published_at);


    const onCopy = () => {
        navigator.clipboard.writeText(window.location.href);
    }

    const onShare = () => {
        if (navigator.share) {
            navigator.share({
                title: document.title,
                text: document.title,
                url: window.location.href,
            })
                .then(() => console.log('Successful share'))
                .catch((error) => console.log('Error sharing', error));
        }
    }

    const [isOpen, setIsOpen] = useState(true);
    const [data, setData] = useState(undefined)
    const [error, setError] = useState(undefined)
    useEffect(() => {
        const fetchPost = async () => {
            try {
                const response = await fetch(body)
                console.log("response", response)
                if (!response.ok) {
                    throw new Error('Network response was not ok')
                }
                const text = await response.text()
                setData(text)
            } catch (error) {
                setError(error)
            }
        }
        fetchPost()
    }, [])
    // const { currentUser } = useContext(AuthContext);
    // const currentUserId = currentUser && currentUser.userId;

    return (
        <div className='post'>
            {/* <PostImage src={cover_image} alt={`Cover image for ${title}`} /> */}
            <div className='post__body'>
                <div className='post__author'>
                    {/* <Avatar link={`/users/${author.id}`} src={author} /> */}
                    <div className='author__details'>
                        <Link to="#">
                            <h4>{author?.name}</h4>
                        </Link>
                        <p>{createdAt}</p>
                    </div>
                </div>
                <h1 className='post__heading'>{title}</h1>
                {/* <PostTags tags={tags} /> */}
                <div className='post__text'>
                    <div className="table-container">
                        <ReactMarkdown
                            // components={SyntaxHighlight}
                            remarkPlugins={[gfm, remarkGfm]}
                            components={{
                                table: Table,
                                SyntaxHighlight
                            }}
                            children={data} >
                            {data}
                        </ReactMarkdown>
                    </div>
                </div>
                {/* <div className="post__share">
                    <ShareButton onCopy={onCopy} onShare={onShare} />
                </div> */}

                {/* <div className='post__auth'>
                    {currentUserId === author.id && (
                        <Link className='auth__edit' to={`/posts/${titleURL}/${id}/edit`}>
                            Edit Post
                        </Link>
                    )}

                    <DeletePost authorId={author.id} />
                </div> */}
            </div>
            {/* {post.comments && <Comments postId={id} postAuthor={author} />} */}
            <Collapsible open={isOpen}>
                <div className="section" style={{ height: "50vh" }}>
                    <DiscussionEmbed
                        shortname='example'
                        config={
                            {
                                url: `https://zimpler.in/post/${id}`,
                                identifier: id,
                                title: title,
                                language: 'en' //e.g. for Traditional Chinese (Taiwan)	
                            }
                        }
                    />
                </div>
            </Collapsible>
        </div>
    );
};

export default PostContent;
