import React from "react";
import CardComponent from "./CardComponent";
import News from "./News";
import help from "../resources/help";
import { posts } from "../resources/articles";
import { Link } from "react-router-dom";
import { zimplerLogoNoBackground } from "../resources/config";

const RightSidebar = () => {
  return (
    <aside className="rightBar">
      <div className="rightBar__card-hackathon">
        <p>
          <img src={zimplerLogoNoBackground} alt="" />
        </p>
        <h2>
          <Link to="/#">Streamline your knowledge with Zimpler</Link>
        </h2>

        <p>
          We simplify your reading experience by delivering crisp and insightful articles. Say goodbye to cluttered information and hello to simplicity.
          <strong>
            {/* <Link to="/#">&nbsp;Get Smarter with Zimpler</Link> */}
          </strong>
        </p>
      </div>

      <CardComponent tag="Listings" articles={posts} />

      {/* <CardComponent tag="#news" articles={news} /> */}
      <News />

      {/* <CardComponent tag="#help" articles={help} /> */}
    </aside>
  );
};

export default RightSidebar;
