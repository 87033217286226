import getNews from '../apis/newsAPI';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const News = () => {
    const [news, setNews] = useState([]);

    useEffect(() => {
        getNews().then(data => {
            console.log(data);
            setNews(data);
        });
    }, []);

    // Render the articles
    return (
        <>
            {/* <CardComponent tag="#news" articles={news} /> */}
            <div className='card'>
                {/* <ul>
                    {news?.map((article) => {
                        return (
                            <li key={article.id}>
                                <a href="/#">{article.title}</a> <br />
                                <small>{article.description}</small>
                                {article.newarticle && <span>new</span>}
                            </li>
                        )
                        // return <NewsCard key={article.title} article={article} />;
                    })}
                </ul> */}
                <header>
                    <h3>What's up with the world!</h3>
                    <Link to={{
                        pathname: '/newsrenderer',
                        search: `data=${encodeURIComponent(JSON.stringify(news))}`
                    }}>
                        <small>see all</small>
                    </Link>
                </header>

                <ul>
                    {Array.isArray(news) && news?.map((a) => {
                        return (
                            <li key={a.id}>
                                <Link to={{
                                    pathname: '/newsrenderer',
                                    search: `data=${encodeURIComponent(JSON.stringify(a))}`
                                }}
                                >{a.title}</Link> <br />
                                <small>{a.author}{"  "}</small>
                                {a.publishedAt && <span>new</span>}
                            </li>
                        );
                    })}
                </ul>
            </div>
        </>

    )
}

export default News;

